import * as React from "react";

import ExitingSiteModal from "./ExitingSiteModal";

type ButtonProps = {
  children: JSX.Element | string;
  to?: string;
  onClick?: VoidFunction;
  external?: boolean;
  dark?: boolean;
  wide?: boolean;
  newTab?: boolean;
};
const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  to,
  external,
  dark,
  wide,
  onClick,
  newTab,
}: ButtonProps) => {
  const [exitingModalOpen, setExitingModalOpen] = React.useState(false);

  const classes = `border-4 inline-block transition-colors text-center text-button ${themeClasses(
    dark,
    wide
  )}`;

  if (external) {
    const onClick = (e: MouseEvent) => {
      e.preventDefault();
      setExitingModalOpen(!exitingModalOpen);
    };
    return (
      <>
        <a onClick={onClick} href={to}>
          <div className={classes}>{children}</div>
        </a>
        <ExitingSiteModal
          destination={to}
          isOpen={exitingModalOpen}
          close={() => {
            setExitingModalOpen(false);
          }}
        />
      </>
    );
  }
  if (to) {
    const props: { [key: string]: string } = {};
    if (newTab) {
      props["target"] = "_blank";
    }
    return (
      <a onClick={onClick} href={to} {...props}>
        <div className={classes}>{children}</div>
      </a>
    );
  }
  return (
    <button className={classes} onClick={onClick}>
      {children}
    </button>
  );

  function themeClasses(isDark?: boolean, isWide?: boolean) {
    let classes = "";
    if (isDark) {
      classes += "border-white text-white hover:text-black hover:bg-white ";
    } else {
      classes += "border-black text-black hover:text-white hover:bg-black ";
    }

    if (isWide) {
      classes += "py-2 w-full md:w-40 lg:w-64";
    } else {
      classes += "py-2 px-12 md:w-72";
    }

    return classes;
  }
};

export default Button;
